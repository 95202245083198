<template>
  <div class="container">
    <h1 id="title">ALCOHOL</h1>
    <v-divider color="black"></v-divider>
    <div class="menu">
      <div v-for="item in items" :key="item">
        <v-row class="item">
          <v-col cols="10">
            <div class="name_en">{{item.name_en}}<span style="font-size: 13px; font-weight: 400; margin-left: 3px;">{{item.mini_word}}</span></div>
            <div class="desc">{{item.description}}</div>
          </v-col>
          <v-col cols="1">
            <div class="price">{{item.price_taxed}}</div>
            <div class="price">{{item.price}}</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import alcohol from '../assets/alcohol-data.json'
  export default {
    name: 'Drink',
    data() {
      return {
        items: alcohol
      }
    }
  }
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 25px;
  margin: 33px 0px 7px 0px;
  text-align: center;
}

.menu {
  margin-top: 30px;
  margin-left: 30px;
}

.item {
  margin-bottom: 26px;
}

.name_en {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight:600;
  padding-bottom: 7px;
}
.name_ja {
  font-family: Helvetica, arial, sans-serif;
  font-size: 11px;
}
.desc {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 9px;
  font-weight: 500;
}

.price {
  font-size: 10px;
  text-align: center;
}
</style>