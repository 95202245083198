<template>
  <div class="container">
    <h1 id="title">SWEETS</h1>
    <v-divider color="black"></v-divider>
    <div class="menu">
      <div v-for="item in items" :key="item">
        <v-row class="item">
          <v-col cols="10">
            <div v-if="!item.is_kanji" class="sec_title">{{item.sec_title}}</div>
            <div v-if="item.is_mini_title" class="mini_title">{{item.mini_title}}</div>
            <div v-if="item.is_kanji" class="flex-container">
                <div class="name_en">{{item.name_en}}</div>
                <div class="name_jp">{{item.name_jp}}</div>
            </div>
          </v-col>
          <v-col cols="1">
            <div class="price">{{item.price_taxed}}</div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="statement text-right">
      <div class="text_1">* 当店は全て税込み価格の表示となっております。</div>
      <div class="text_2">* テイクアウトの場合、税率が異なりますので別価格となります。</div>
      <div class="text_3">* 原材料の一部は小麦・乳・卵・そば・落花生・エビ・かにを含む製造ラインで</div>
      <div class="text_4">生産されたものを使用しています。アレルギーの方はご注意ください。</div>
    </div>
  </div>
</template>

<script>
  import sweets from '../assets/sweets-data.json'
  export default {
    name: 'Sweets',
    data() {
      return {
        items: sweets
      }
    }
  }
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'YujiSyuku-Regular';
  src: url('~@/assets/fonts/YujiSyuku-Regular.ttf') format('truetype');
}

.container {
  max-width: 100%;
  height: 400px;
  margin: 0;
  padding: 0;
}

#title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 25px;
  margin: 33px 0px 7px 0px;
  text-align: center;
}

.menu {
  margin-top: 30px;
  margin-left: 30px;
}

.item {
  margin-bottom: 5px;
}

.sec_title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight:600;
  font-weight: medium;
  padding-bottom: 22px;
}

.mini_title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight:600;
  font-weight: medium;
}

.flex-container {
  display: flex;
  align-items: center; /* 縦方向の位置を揃えるために使用 */
  padding-bottom: 5px;
}

.name_en {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 13px;
  font-weight:600;
}

.name_jp {
  font-family: "YujiSyuku-Regular","游教科書体 横用","YuKyokashoYoko","YuMincho";
  font-size: 12px;
}

.desc {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 9px;
  font-weight: 500;
}

.no_desc {
  margin-bottom: 0px;
}

.price {
  font-size: 10px;
  text-align: center;
}

.statement {
  margin: 20px 20px;
  height: 40px;
  font-size: 8px;
  text-align: right;
}

.text_3 { /* 句読点がない為 */
  margin-right: 5px;
}
</style>