<template>
  <v-container class="pa-0">
    <h1 id="title">MENU</h1>
    <v-divider color="black"></v-divider>
  </v-container>
</template>

<script>
  export default {
    name: 'menu'
  }
</script>

<style scoped>
#menu {
  height: 100%;
}

.container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 25px;
  margin: 33px 0px 7px 0px;
  text-align: center;
}
</style>