<template>
  <div class="container">
    <h1 id="title">THANK YOU</h1>
    <v-divider color="black"></v-divider>
    <div class="menu">
      <div v-for="item in items" :key="item">
        <v-row>
          <v-col>
            <div class="text-1">{{item.instagram}}</div>
            <a class="text-2" href="https://instagram.com/e2102.cafe">{{item.instagram_link}}</a>
            <div class="text-3">{{item.facebook}}</div>
            <div class="text-4">{{item.facebook_link}}</div>
            <div class="text-5">{{item.wifi}}</div>
            <div class="text-6">{{item.pass}}</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',
    data: () => ({
      items: [
        {
          instagram: 'Instagram:',
          instagram_link: '@e2102.cafe',
          facebook: 'Facebook:',
          facebook_link: '@e2102.cafe',
          wifi: 'Wifi: E2102_cafe',
          pass: 'Pass: E2102_cafe',
        },
      ],
    }),
  }
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Times, Helvetica, arial, sans-serif;;
}

.container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#title {
  font-family: Times, Helvetica, arial, sans-serif;
  font-size: 25px;
  margin: 33px 0px 7px 0px;
  text-align: center;
}
.menu {
  margin-top: 100px;
  text-align: center;
}

.text-1 {
  font-size: 15px;
}
.text-2 {
  text-decoration: none;
  color: black;
}
.text-3 {
  margin-top: 50px;
}
.text-5 {
  margin-top: 50px;
}
</style>