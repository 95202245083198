<template>
  <div id="menu">
    <div class="home">
    <component :is="currentComponent"></component>
    </div>
    <v-footer app bottom fixed padless color="white">
      <v-list width="100%">
        <v-divider></v-divider>
        <v-list-item width="100%">
          <v-btn @click="currentComponent = 'Sweets'" text>SWEETS</v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-btn @click="currentComponent = 'Coffee'" text>COFFEE</v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-btn @click="currentComponent = 'Drink'" text>DRINK</v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-btn @click="currentComponent = 'Alcohol'" text>ALCOHOL</v-btn>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="list-item-center">
          <button v-on:click="currentComponent = 'About'" width="100%">
            <img class="logo" v-bind:src="imgPath">
          </button>
        </v-list-item>
      </v-list>
    </v-footer>

  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Sweets from '@/components/Sweets.vue'
import Coffee from '@/components/Coffee.vue'
import Drink from '@/components/Drink.vue'
import About from '@/components/About.vue'
import Alcohol from '@/components/Alcohol.vue'

document.addEventListener("dblclick", function(e){ e.preventDefault();}, { passive: false });

export default {
  components: {
    Menu,
    Sweets,
    Coffee,
    Drink,
    About,
    Alcohol,
  },
  data() {
    return {
    currentComponent: "Menu",
    imgPath: require('@/assets/logo.png')
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

.list-item-center {
  justify-content: center;
  text-align: left;
}

.logo {
  width: 60px;
  height: 15px;
}

.v-list-item {
  padding: 0;
  min-height: 40px;
}

.v-btn {
  padding: 0;
  font-weight: bold;
}

.v-btn.v-size--default {
  font-size: 1.04rem;
}

.v-list {
  padding-bottom: 0;
}
</style>
